.oddstatebtn {
    background: #fff;
    color: #f7931e;
    border: 1px solid #f7931e !important;
    border-radius: 0px;
    width: 110px;
}
.oddstatebtn.active {
    background: #f7931e !important;
    color: #fff !important;
}
.headcol {
    position: absolute;
    left: 0;
    top: auto;
    border-top-width: 1px;
    /*only relevant for first row*/
    margin-top: -1px;
    /*compensate for top border*/
    white-space: nowrap;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 999;
}
.spread_time li {
    display: inline-block;
    margin-left: 10px;
    list-style: none;
    /* float: left; */
    vertical-align: middle;
}
.spread_time li:first-child {
    margin-left: 0px;
}
